import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Card } from 'react-native-paper';

import { FormRow, Layout, RequestHandler } from '../../../components';
import { useNotificationsContext } from '../../../context';
import stylesGlobal from '../../../globalStyles';
import { useOrderMutation, useOrderQuery } from '../../../hooks';
import useParams from '../../../hooks/useParams';
import { orderSchema } from '../../../services/validation';
import { checkIsArraysAreEqualByKeys } from '../../../utils/checkIsArraysAreEqualByKeys';
import {
  DeliveryProps,
  DescriptionProps,
  OrderCustomer,
  OrderItems,
  OrderStatus,
  PaymentProps,
} from './Properties';

const Order = () => {
  const params = useParams();
  const id = params?.id;
  const [initOrderItems, setInitOrderItems] = useState(null);

  const { data, ...orderQueryProps } = useOrderQuery();

  const [mutation, { loading }] = useOrderMutation(data?.order?.id);

  const { showNotification } = useNotificationsContext();

  const order = data?.order;

  const customer = order?.customer;

  const contacts = customer?.phones
    ?.map((phone) => ({ value: phone?.phone }))
    .concat(customer?.emails?.map((email) => ({ value: email?.email }))) || [
    { value: '' },
  ];

  const methods = useForm({
    resolver: yupResolver(orderSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      id: +order?.id || undefined,
      status: order?.status?.id || '',
      defaultStatus: order?.status?.id || '',
      managerComment: order?.managerComment || '',
      user: order?.user?.id || '',
      source: order?.source?.id || '',
      paymentType: order?.paymentType?.id || '',
      paymentStatus: order?.paymentStatus?.id || '',
      orderItems: order?.orderItems,
      shippingType: order?.shippingType?.id || '',
      deliveryInformation: {
        city: order?.deliveryInformation?.city || '',
        address: order?.deliveryInformation?.address || '',
        comment: order?.deliveryInformation?.comment || '',
        ttn: order?.deliveryInformation?.ttn || '',
      },
      novaPoshtaShipping: {
        serviceType: order?.novaPoshtaShipping?.serviceType || 'WarehouseWarehouse',
        cityRef: '',
        street: order?.novaPoshtaShipping?.street || '',
        streetRef: order?.novaPoshtaShipping?.streetRef || '',
        addressRef: order?.novaPoshtaShipping?.street
          ? ''
          : order?.novaPoshtaShipping?.addressRef || '',
        payerType: order?.novaPoshtaShipping?.payerType || '',
        buildingNumber: order?.novaPoshtaShipping?.buildingNumber || '',
        flatNumber: order?.novaPoshtaShipping?.flatNumber || '',
      },
      ukrposhtaShipping: {
        deliveryType: order?.ukrposhtaShipping?.deliveryType || 'W2W',
        cityId: order?.ukrposhtaShipping?.cityId || null,
        cityName: order?.ukrposhtaShipping?.cityName || '',
        cityFullName: order?.ukrposhtaShipping?.cityFullName || '',
        warehouseId: order?.ukrposhtaShipping?.warehouseId || null,
        warehouseName: order?.ukrposhtaShipping?.warehouseName || '',
        street: order?.ukrposhtaShipping?.street || '',
        paidByRecipient: order?.ukrposhtaShipping?.paidByRecipient || true,
        houseNumber: order?.ukrposhtaShipping?.houseNumber || '',
        apartmentNumber: order?.ukrposhtaShipping?.apartmentNumber || '',
        postcode: order?.ukrposhtaShipping?.postcode || '',
      },
      customer: {
        id: +customer?.id || null,
        firstName: customer?.firstName || '',
        lastName: customer?.lastName || '',
        middleName: customer?.middleName || '',
        contacts,
      },
      isReservationCanceled: !id,
      isCallbackOff: order?.callbackOff || false,
      customerComment: order?.customerComment || '',
    },
  });

  useEffect(() => {
    methods.reset({
      id: +order?.id || undefined,
      status: order?.status?.id || '',
      defaultStatus: order?.status?.id || '',
      managerComment: order?.managerComment || '',
      user: order?.user?.id || '',
      source: order?.source?.id || '',
      paymentType: order?.paymentType?.id || '',
      paymentStatus: order?.paymentStatus?.id || '',
      orderItems: order?.orderItems,
      shippingType: order?.shippingType?.id || '',
      deliveryInformation: {
        city: order?.deliveryInformation?.city || '',
        address: order?.deliveryInformation?.address || '',
        comment: order?.deliveryInformation?.comment || '',
        ttn: order?.deliveryInformation?.ttn || '',
      },
      novaPoshtaShipping: {
        serviceType: order?.novaPoshtaShipping?.serviceType || 'WarehouseWarehouse',
        cityRef: order?.novaPoshtaShipping?.cityRef || '',
        street: order?.novaPoshtaShipping?.street || '',
        streetRef: order?.novaPoshtaShipping?.streetRef || '',
        addressRef: order?.novaPoshtaShipping?.street
          ? ''
          : order?.novaPoshtaShipping?.addressRef || '',
        payerType: order?.novaPoshtaShipping?.payerType || '',
        buildingNumber: order?.novaPoshtaShipping?.buildingNumber || '',
        flatNumber: order?.novaPoshtaShipping?.flatNumber || '',
      },
      ukrposhtaShipping: {
        deliveryType: order?.ukrposhtaShipping?.deliveryType || 'W2W',
        cityId: order?.ukrposhtaShipping?.cityId || null,
        cityName: order?.ukrposhtaShipping?.cityName || '',
        cityFullName: order?.ukrposhtaShipping?.cityFullName || '',
        street: order?.ukrposhtaShipping?.street || '',
        paidByRecipient: order?.ukrposhtaShipping?.paidByRecipient || true,
        houseNumber: order?.ukrposhtaShipping?.houseNumber || '',
        apartmentNumber: order?.ukrposhtaShipping?.apartmentNumber || '',
        postcode: order?.ukrposhtaShipping?.postcode || '',
        warehouseId: order?.ukrposhtaShipping?.warehouseId || null,
        warehouseName: order?.ukrposhtaShipping?.warehouseName || '',
      },
      customer: {
        id: +customer?.id || null,
        firstName: customer?.firstName || '',
        lastName: customer?.lastName || '',
        middleName: customer?.middleName || '',
        contacts,
      },
      isReservationCanceled: !id,
      isCallbackOff: order?.callbackOff || false,
      customerComment: order?.customerComment || '',
    });
    setInitOrderItems(order?.orderItems);

    setTimeout(() => {
      methods.reset({
        ...methods.getValues(),
      });
    }, 300);
  }, [order, methods.reset, id]);

  const onSubmit = (values) => {
    if (!values.orderItems.length) {
      showNotification({
        type: 'error',
        title: 'errors.default',
        message: 'products.required',
      });
      return;
    }

    const orderItems = values.orderItems?.map(
      ({ id: itemId, product, pricePerPcs, quantity, availability }) => {
        return {
          id: itemId ? +itemId : undefined,
          allowedConditions: availability?.allowedConditions?.map(({ id }) => ({
            id: +id,
          })),
          pricePerPcs: +pricePerPcs,
          product: +product.id,
          quantity: +quantity,
        };
      },
    );

    const { id, firstName, lastName, middleName, contacts } = values.customer;
    const { city, ttn, address, comment } = values.deliveryInformation;

    const phones = contacts
      ?.map(({ value }) => value)
      .filter((value) => value)
      ?.filter((contact) => contact.match(/^\d{12}$/));

    const emails = contacts
      ?.map(({ value }) => value)
      .filter((value) => value)
      ?.filter((contact) =>
        contact.match(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/),
      );

    const statuses = data?.statuses;

    const { reservationType } = statuses.find(({ id }) => +id === +values.status) || {};

    const sendOrderItems =
      (reservationType === 'sent' || reservationType === 'reserved') &&
      values.isReservationCanceled;

    const isChangedOrderItems = !checkIsArraysAreEqualByKeys(
      initOrderItems,
      values?.orderItems,
      ['quantity', 'product.sku', 'pricePerPcs'],
    );

    const orderInput = {
      id: values.id ? +values.id : undefined,
      user: +values.user,
      managerComment: values.managerComment,
      source: +values.source,
      status: +values.status,
      paymentType: +values.paymentType,
      paymentStatus: +values.paymentStatus,
      shippingType: +values.shippingType,
      deliveryInformation: {
        city: city.id,
        ttn,
        address,
        comment,
      },
      orderItems: sendOrderItems || isChangedOrderItems ? orderItems : undefined,
      customer: {
        ...(!!id && { id: Number(id) }),
        firstName,
        lastName,
        middleName,
        phones,
        emails,
      },
      ...(+values.shippingType === 1 && {
        novaPoshtaShipping: {
          ...values.novaPoshtaShipping,
          streetRef:
            values.novaPoshtaShipping.serviceType === 'WarehouseDoors'
              ? values.novaPoshtaShipping.streetRef
              : undefined,
          street:
            values.novaPoshtaShipping.serviceType === 'WarehouseDoors'
              ? values.novaPoshtaShipping.street
              : undefined,
        },
      }),
      ...(+values.shippingType === 2 && {
        ukrposhtaShipping: {
          ...values.ukrposhtaShipping,
          street:
            values.ukrposhtaShipping.deliveryType === 'W2D'
              ? values.ukrposhtaShipping.street
              : undefined,
        },
      }),
    };

    mutation({
      variables: {
        input: orderInput,
      },
    });
  };

  return (
    <RequestHandler {...orderQueryProps}>
      <FormProvider {...methods}>
        <Layout
          onActionButtonPress={methods.handleSubmit(onSubmit)}
          actionButtonIsLoading={loading}
          actionButtonType='save'
        >
          <FormRow>
            <FormRow.Left>
              <Card style={stylesGlobal.form}>
                <Card.Content>
                  <OrderStatus
                    statuses={data?.statuses || []}
                    users={data?.users || []}
                    sources={data?.sources || []}
                  />
                </Card.Content>
              </Card>
              <Card style={stylesGlobal.form}>
                <Card.Content>
                  <OrderItems statuses={data?.statuses} />
                </Card.Content>
              </Card>
              <Card style={stylesGlobal.form}>
                <Card.Content>
                  <OrderCustomer />
                </Card.Content>
              </Card>
            </FormRow.Left>
            <FormRow.Right>
              <Card style={stylesGlobal.form}>
                <Card.Content>
                  <PaymentProps
                    paymentTypes={data?.paymentTypes || []}
                    paymentStatuses={data?.paymentStatuses || []}
                  />
                </Card.Content>
              </Card>
              <Card style={stylesGlobal.form}>
                <Card.Content>
                  <DeliveryProps shippingTypes={data?.shippingTypes || []} />
                </Card.Content>
              </Card>
              <Card style={stylesGlobal.form}>
                <Card.Content>
                  <DescriptionProps name='managerComment' />
                </Card.Content>
              </Card>
            </FormRow.Right>
          </FormRow>
        </Layout>
      </FormProvider>
    </RequestHandler>
  );
};

export default Order;
